import { ICheckoutState } from "@/models/store/checkout";
import {
    EGender,
    EPaymentMethod,
    EStatusStepper,
    ETourLanguage,
    ETourOption,
} from "@/models/common/enums";
import { SectionsTitleText } from "@/models/common/common";
import colors from "../../styles/colors.module.scss";

export const getDeaultTourDetail = (): ICheckoutState["tourDetail"] => ({
    tourId: "",
    tourType: "",
    tourTitle: "",
    tourCode: "",
    coverImage: "",
    coverVideo: "",
    associatedTours: [],
    nightDestinations: [],
    price: "",
    slots: [],
    whatWillYouSee: "",
    whatWillYouSeeImage: "",
    whatIsIncluded: [],
    itineraries: [],
    destinations: [],
    startingPrice: "",
    status: "",
    bookingCode: "",
    chainCode: "",
    languages: [],
});

export const getDefaultCheckoutState = (): ICheckoutState => ({
    orderData: {
        orderId: "",
        chainCode: "",
        bookingCode: "",
        paymentMethodNonce: "",
        paymentType: "singlePayment",
        customerId: "",
        orderTransactionId: "",
        originalAmount: 0,
        totalAmount: 0,
        coupon: undefined,
        rooms: [],
        pets: false,
        mainCustomer: {
            name: "",
            surname: "",
            email: "",
            phone: "",
            country: "IT",
            specialNeed: "",
            cityOfBirth: "",
            fiscalCode: "",
            gender: EGender.NONE,
            emergencyContactName: "",
            emergencyContactPhone: "",
            emergencyContactRelationship: "",
            userType: "PRIVATE",
            address: "",
            city: "",
            county: "",
            zipCode: "",
        },
        travelers: [],
        tour: {
            tourId: "",
            option: ETourOption.QUALITY,
            date: "",
            amount: 0,
        },
        status: "",
        specialRequests: "",
        toInvoiced: true,
        invoice: {
            addressCity: "",
            addressExtra: "",
            addressPostalCode: "",
            addressStreet: "",
            addressProvince: "",
            name: "",
            vatNumber: "",
            taxCode: "",
            country: "IT",
        },
        markup: 0,
        netPrice: 0,
    },
    tourDetail: getDeaultTourDetail(),
    statusStep: EStatusStepper.STEP1,
    activeNext: false,
    btnNextText: "Confirm and continue",
    startTime: new Date(),
    submitPayment: false,
    languageSelected: ETourLanguage.EN,
    paymentMethod: EPaymentMethod.NONE,
    clickDate: false,
    clickLanguage: false,
    clickConfirmDate: false,
});

export const checkoutTitle = [
    {
        text: "Checkout",
        color: colors.textPrimary,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

export const titleSelectCoupon: SectionsTitleText[] = [
    {
        text: "Select a coupon",
        color: colors.textPrimary,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

export const subTitleSelectCoupon: SectionsTitleText[] = [
    {
        text: "Select a coupon to apply to your booking",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];
