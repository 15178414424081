import {
    BrochuresEntity,
    CardBrochuresContent,
    CardTikTokContent,
    CardVisitContent,
    DestinationToExploreEntity,
    HomeResponseTO,
    StaticMediaEntity,
    StepsInfo,
    ToursByDestinationEntity,
    ToursByTourTypeEntity,
    ToursEntity,
    VideoreviewsEntity,
} from "../../models/home";
import {
    NavBarResponseTO,
    SectionsTitleText,
} from "../../models/common/common";
import colors from "../../styles/colors.module.scss";
import { reviewContents } from "./detailMocks";
import { URLS } from "../constants";

export const footerSectionsOne = [
    {
        title: "Quick menu",
        id: "quikmenu-1",
        links: [
            {
                title: "All Tour",
                id: "All Tour",
                href: URLS.allTours,
            },
            {
                title: "About us",
                id: "About us",
                href: URLS.playWhitUs,
            },
            {
                title: "Our Italian stories",
                id: "Our Italian stories",
                href: URLS.italianStories,
            },
            {
                title: "Contact us",
                id: "Contact us",
                href: URLS.contatcts,
            },
        ],
    },
    {
        title: "Need Help?",
        id: "Need Help?",
        links: [
            {
                title: "FAQ",
                id: "FAQ",
                href: URLS.faq,
            },
            {
                title: "Terms & Conditions",
                id: "Terms & Conditions",
                href: URLS.terms,
            },
            {
                title: "Policy & Privacy",
                id: "Policy & Privacy",
                href: URLS.privacyPolicies,
            },
        ],
    },
];

export const footerSectionsTwo = [
    {
        title: "Tours",
        id: "Tours",
        links: [
            {
                title: "Accross Italy",
                id: "Accross Italy",
                href: URLS.acrossItaly,
            },
            {
                // eslint-disable-next-line sonarjs/no-duplicate-string
                title: "Into the region",
                id: "Into the region1",
                href: URLS.intoTheRegion,
            },
            {
                // eslint-disable-next-line sonarjs/no-duplicate-string
                title: "Down the city",
                id: "Down the city2",
                href: URLS.intoTheCity,
            },
        ],
    },
    {
        title: "Destinations",
        id: "Destinations",
        links: [
            {
                title: "Venice",
                id: "Venice",
                href: URLS.venice,
            },
            {
                title: "Rome",
                id: "Rome",
                href: URLS.rome,
            },
            {
                title: "Naples",
                id: "Naples",
                href: URLS.naples,
            },
            {
                title: "Florence",
                id: "Florence",
                href: URLS.florence,
            },
            {
                title: "Milan & Como",
                id: "Milan & Como",
                href: URLS.milan,
            },
        ],
    },
];

export const sectionTitle: SectionsTitleText[] = [
    {
        text: "What do you expect from the",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "perfect Italian",
        color: colors.textPrimary,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "Tour?",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
];
export const italianTourSingleSectionTitle: SectionsTitleText[] = [
    {
        text: "Our Play Italy tours",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

export const italianTourSingleSectionSubTitle: SectionsTitleText[] = [
    {
        text: "Did you see something you like?",
        color: colors.textGray,
        fontWeight: "",
        newLine: true,
    },
    {
        text: "Explore our tour section, it’s full of suprises.",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];

export const brochuresTitle: SectionsTitleText[] = [
    {
        text: "Download our",
        color: colors.textWhiteLight,
        fontWeight: "fw-bold",
        newLine: true,
    },
    {
        text: "brochures",
        color: colors.textGreen,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

const card1Title: SectionsTitleText[] = [
    {
        text: "Play Italy",
        color: colors.textPrimary,
        fontWeight: "fw-bold",
        newLine: true,
    },
    {
        text: "All the tours",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

const card2Title: SectionsTitleText[] = [
    {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        text: "Across Italy",
        color: colors.textPrimary,
        fontWeight: "fw-bold",
        newLine: true,
    },
    {
        text: "Brochure",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

const card3Title: SectionsTitleText[] = [
    {
        text: "Into the Region",
        color: colors.textPrimary,
        fontWeight: "fw-bold",
        newLine: true,
    },
    {
        text: "Brochure",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

const card4Title: SectionsTitleText[] = [
    {
        text: "Down the city",
        color: colors.textPrimary,
        fontWeight: "fw-bold",
        newLine: true,
    },
    {
        text: "Brochure",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

export const sectionSubTitle: SectionsTitleText[] = [
    {
        text: "You want to see it all? Do you have a region of preference in mind?",
        color: colors.textGray,
        fontWeight: "",
        newLine: true,
    },
    {
        text: "Or you just want to stop by a particular city? We have many solutions to suit your needs.",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];

export const brochuresSubTitle: SectionsTitleText[] = [
    {
        text: "Do you want to know what we mean by esperienza italiana? Then start experiencing it now.",
        color: colors.textGrayLight,
        fontWeight: "fw-bold",
        newLine: true,
    },
];

const card1SubTitle: SectionsTitleText[] = [
    {
        text: "Take a look at our tour offers. Follow your hunch and choose your favorite Italian tour!",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];
const card2SubTitle: SectionsTitleText[] = [
    {
        text: "If you want to have a complete view of our Country, the across Italy formula is made for you!",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];
const card3SubTitle: SectionsTitleText[] = [
    {
        text: "You’re already interested in a specific Italian region? We have what you need, swipe them all!",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];
const card4SubTitle: SectionsTitleText[] = [
    {
        text: "You’ve always wanted to visit a specific Italian city? We offer this solution as well, come with us!",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];

export const card1Content: CardBrochuresContent = {
    title: card1Title,
    subTitle: card1SubTitle,
    imagesrc: "/images/brochures/all_brochures.jpeg",
};

export const card2Content: CardBrochuresContent = {
    title: card2Title,
    subTitle: card2SubTitle,
    imagesrc: "/images/brochures/Across_italy_01.jpeg",
};

export const card3Content: CardBrochuresContent = {
    title: card3Title,
    subTitle: card3SubTitle,
    imagesrc: "/images/brochures/into_the_region.jpeg",
};

export const card4Content: CardBrochuresContent = {
    title: card4Title,
    subTitle: card4SubTitle,
    imagesrc: "/images/brochures/down_the_city_01.jpeg",
};
export const socialTitle: SectionsTitleText[] = [
    {
        text: "Who is",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "Play Italy",
        color: colors.textPrimary,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "?",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
];
export const tourReviewTitle: SectionsTitleText[] = [
    {
        text: "Our",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "tour reviews",
        color: colors.textPrimary,
        fontWeight: "fw-bold",
        newLine: false,
    },
];
export const wantTovisitTitle: SectionsTitleText[] = [
    {
        text: "The city I want",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "to visit the most",
        color: colors.textPrimary,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "is...",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

export const socialSubTitle: SectionsTitleText[] = [
    {
        text: "Play Italy is a brand made of Italian Local Expert, ",
        color: colors.textGray,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "born out of the will of a few entrepreneurs to completely revolutionize the way",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
    {
        text: "of booking and living trips to Italy. This was possible thanks to the sharing of their skills, experience, traditions and resources to",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
    {
        text: "create a unique product that’s easy and fast to book.",
        color: colors.textGray,
        fontWeight: "fw-bold",
        newLine: true,
    },
];
export const italianStoriesTitle: SectionsTitleText[] = [
    {
        text: "Italian",
        color: colors.textPrimary,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "stories",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

export const italianStoriesSubTitle: SectionsTitleText[] = [
    {
        text: "Let’s go on an adventure together.",
        color: colors.textGrayLight,
        fontWeight: "",
        newLine: false,
    },
];

export const wantTovisitSubTitle: SectionsTitleText[] = [
    {
        text: "Explore all of our destinations:",
        color: colors.textGrayLight,
        fontWeight: "",
        newLine: false,
    },
];

export const tikTokTitle: SectionsTitleText[] = [
    {
        text: "But you",
        color: colors.textWhiteLight,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "don’t need",
        color: colors.textGreen,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "to hear it from us",
        color: colors.textWhiteLight,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

export const tikTokSubTitle: SectionsTitleText[] = [
    {
        text: "See what our people had to say about it.",
        color: colors.textGrayLight,
        fontWeight: "",
        newLine: false,
    },
];

const tikTokName = "Antone Heller";
const tikTokPlace = "LONDON (UK)";
const tikTokVideoUrl = "https://www.youtube.com/embed/vlDzYIIOYmM";
const tikTokUserImageUrl =
    "https://images.unsplash.com/photo-1554151228-14d9def656e4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8cGVyc29uYXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=600&q=60";

const cardTikTokContent1: CardTikTokContent = {
    name: tikTokName,
    place: tikTokPlace,
    userImageUrl: tikTokUserImageUrl,
    imageCoverUrl:
        "https://www.liebesmeer.de/wp-content/uploads/2020/08/Sie-m%C3%B6chte-M%C3%A4nner-kennenlernen.jpg",
    videoUrl: tikTokVideoUrl,
};
const cardTikTokContent2: CardTikTokContent = {
    name: tikTokName,
    place: tikTokPlace,
    userImageUrl: tikTokUserImageUrl,
    imageCoverUrl:
        "https://images.unsplash.com/photo-1527631746610-bca00a040d60?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHRyYXZlbHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=600&q=60",
    videoUrl: tikTokVideoUrl,
};
const cardTikTokContent3: CardTikTokContent = {
    name: tikTokName,
    place: tikTokPlace,
    userImageUrl: tikTokUserImageUrl,
    imageCoverUrl:
        "https://imageio.forbes.com/specials-images/imageserve/61a7ec71129e97ce589f5183/happy-woman/960x0.jpg?format=jpg&width=960",
    videoUrl: tikTokVideoUrl,
};
const cardTikTokContent4: CardTikTokContent = {
    name: tikTokName,
    place: tikTokPlace,
    userImageUrl: tikTokUserImageUrl,
    imageCoverUrl:
        "https://images.unsplash.com/photo-1502791451862-7bd8c1df43a7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjd8fHRyYXZlbHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=600&q=60",
    videoUrl: tikTokVideoUrl,
};

export const cardTikTokContents = [
    cardTikTokContent1,
    cardTikTokContent2,
    cardTikTokContent3,
    cardTikTokContent4,
];

export const fourStepsContents: StepsInfo[] = [
    {
        title: [
            {
                text: "Choose your tour",
                color: colors.textBlack,
                fontWeight: "fw-bold",
                newLine: false,
            },
        ],
        subTitle: [
            {
                text: "Book your",
                color: colors.textGray,
                fontWeight: "",
                newLine: false,
            },
            {
                text: "esperienza italiana",
                color: colors.textGray,
                fontWeight: "fw-bold",
                newLine: false,
            },
            {
                text: "based on your desired destinations and activities.",
                color: colors.textGray,
                fontWeight: "",
                newLine: false,
            },
        ],
    },
    {
        title: [
            {
                text: "Book your own flight",
                color: colors.textBlack,
                fontWeight: "fw-bold",
                newLine: false,
            },
        ],
        subTitle: [
            {
                text: "Your Play Italy journey will start in Italy.",
                color: colors.textGray,
                fontWeight: "fw-bold",
                newLine: false,
            },
            {
                text: "Be sure to catch the best flight for your needs.",
                color: colors.textGray,
                fontWeight: "",
                newLine: false,
            },
            {
                text: "Please know we don't provide flight arrangements.",
                color: colors.textGray,
                fontWeight: "",
                newLine: false,
            },
        ],
    },
    {
        title: [
            {
                text: "The adventure begins",
                color: colors.textBlack,
                fontWeight: "fw-bold",
                newLine: false,
            },
        ],
        subTitle: [
            {
                text: "With our",
                color: colors.textGray,
                fontWeight: "",
                newLine: false,
            },
            {
                text: "Italian Experts",
                color: colors.textGray,
                fontWeight: "fw-bold",
                newLine: false,
            },
            {
                text: ", you will be able to explore and enjoy Italy from a totally unique point of view.",
                color: colors.textGray,
                fontWeight: "",
                newLine: false,
            },
        ],
    },
    {
        title: [
            {
                text: "Goodbye, for now",
                color: colors.textBlack,
                fontWeight: "fw-bold",
                newLine: false,
            },
        ],
        subTitle: [
            {
                text: "Was it like you expected it to be? Let us know with a review.",
                color: colors.textGray,
                fontWeight: "",
                newLine: false,
            },
            {
                text: "We value every feedback we get!",
                color: colors.textGray,
                fontWeight: "fw-bold",
                newLine: false,
            },
        ],
    },
];

export const fourStepsTitle: SectionsTitleText[] = [
    {
        text: "Your Play Italy trip in",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "four steps",
        color: colors.textPrimary,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: ":",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

export const fourStepsSubTitle: SectionsTitleText[] = [
    {
        text: "DIscover the easiest ways to visit Italy.",
        color: colors.textGrayLight,
        fontWeight: "",
        newLine: false,
    },
];

export const travelExperiencesTitleGreen: SectionsTitleText[] = [
    {
        text: "Travel experiences for",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: true,
    },
    {
        text: "everyone",
        color: colors.textGreen,
        fontWeight: "fw-bold",
        newLine: false,
    },
];
export const travelExperiencesTitle: SectionsTitleText[] = [
    {
        text: "Travel experiences for",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "everyone",
        color: colors.textPrimary,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

export const travelExperiencesSubTitle: SectionsTitleText[] = [
    {
        text: "You can find our travel offers in both our Quality and Premium versions.",
        color: colors.textGrayLight,
        fontWeight: "",
        newLine: false,
    },
];

export const fourStepsImage: string[] = [
    "https://images.unsplash.com/photo-1539635278303-d4002c07eae3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    "https://images.unsplash.com/photo-1506012787146-f92b2d7d6d96?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80",
    "https://images.unsplash.com/photo-1516483638261-f4dbaf036963?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=686&q=80",
];

export const iwantToVisitContent: CardVisitContent[] = [
    {
        title: "Naples",
        imageUrl:
            "https://images.unsplash.com/photo-1641171104084-4fcd07302f7c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80",
        region: "Campania",
        place: "Castel Ovo and the Lungomare",
    },
    {
        title: "Milan",
        imageUrl:
            "https://images.unsplash.com/photo-1610016302534-6f67f1c968d8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1075&q=80",
        region: "Lombardy",
        place: "Milan Cathedral",
    },
    {
        title: "Sorrento",
        imageUrl:
            "https://images.unsplash.com/photo-1529914266944-527632c9ea58?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8c29ycmVudG98ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60",
        region: "Campania",
        place: "Marina Grande and Marina Piccola",
    },
    {
        title: "Capri",
        imageUrl:
            "https://images.unsplash.com/photo-1627200852595-8e1896b10a42?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
        region: "Campania",
        place: "Blue Grotto",
    },
    {
        title: "Amalfi",
        imageUrl:
            "https://images.unsplash.com/photo-1612698093158-e07ac200d44e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YW1hbGZpfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60",
        region: "Campania",
        place: "Emerald Cave",
    },
    {
        title: "Positano",
        imageUrl:
            "https://images.unsplash.com/photo-1596736743518-eef8c49026b7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
        region: "Campania",
        place: "Church of Santa Maria Assunta",
    },
    {
        title: "Ravello",
        imageUrl:
            "https://images.unsplash.com/photo-1631812331783-44ebb8f2241d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cmF2ZWxsb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=600&q=60",
        region: "Campania",
        place: "Ravello Cathedral",
    },
    {
        title: "Pompei",
        imageUrl:
            "https://images.unsplash.com/photo-1567946454428-4b87b55b2cd2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
        region: "Campania",
        place: "The Forum of Pompeii ",
    },
    {
        title: "Firenze",
        imageUrl:
            "https://images.unsplash.com/photo-1579127208280-ee7baf6d9270?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        region: "Tuscany",
        place: "Santa Maria del Fiore",
    },
    {
        title: "Roma",
        imageUrl:
            "https://images.unsplash.com/photo-1568325006518-d91379e075a7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=662&q=80",
        region: "Lazio",
        place: "Colosseum",
    },
    {
        title: "Siena",
        imageUrl:
            "https://images.unsplash.com/photo-1668265216120-a902b48c690b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Q2F0aGVkcmFsJTIwb2YlMjBTYW50YSUyME1hcmlhJTIwQXNzdW50YXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=600&q=60",
        region: "Tuscany",
        place: "Cathedral of Santa Maria Assunta",
    },
    {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        title: "San Gimignano",
        imageUrl:
            "https://images.unsplash.com/photo-1664286244763-70815903af8c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8U2FuJTIwR2ltaWduYW5vfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60",
        region: "Tuscany",
        place: "Piazza della Cisterna and Towers ",
    },
    {
        title: "Pisa",
        imageUrl:
            "https://images.unsplash.com/photo-1601980740525-fc689655157f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=750&q=80",
        region: "Tuscany",
        place: "The Leaning Tower of Pisa",
    },
    {
        title: "Monteriggioni",
        imageUrl:
            "https://images.unsplash.com/photo-1516108317508-6788f6a160e4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
        region: "Tuscany",
        place: "Mura di Monteriggioni",
    },
    {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        title: "Radda in Chianti",
        imageUrl:
            "https://images.unsplash.com/photo-1616107988298-bf1e00e48723?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8UmFkZGElMjBpbiUyMENoaWFudGl8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60",
        region: "Tuscany",
        place: "Castello Monterinaldi ",
    },
    {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        title: "Val d’Orcia",
        imageUrl:
            "https://images.unsplash.com/7/tuscany.jpg?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80",
        region: "Tuscany",
        place: "Podere Belvedere",
    },
];

const romeFlorenceTuscany: ToursEntity = {
    _id: "640f2cf7f85e0e590ea216e3",
    title: "Rome, Florence and Tuscany 6 days/5 nights Rome – Florence",
    price: "",
    destinations: ["Firenze", "Roma", "San Gimignano", "Pisa"],
    image: "https://d1s9epp90f3vor.cloudfront.net/tours/Rome-Florence-Tuscany.webp",
    duration: 6,
    nightDuration: 5,
    reviews: 0,
    rating: 0,
};

const tasteTuscany: ToursEntity = {
    _id: "640f5b512f1c05383ffbe2b9",
    title: "Taste of Tuscany: food and wine trails between stunning landscapes and dream villages 4 days / 3 nights",
    price: "1000.0",
    destinations: [
        "Firenze",
        "San Gimignano",
        "Radda in Chianti",
        "Val d’Orcia",
    ],
    image: "https://d1s9epp90f3vor.cloudfront.net/tours/Taste-of-Tuscany.webp",
    duration: 4,
    nightDuration: 3,
    reviews: 0,
    rating: 0,
};

const florenceArtCrafts: ToursEntity = {
    _id: "640f4f13eb4b22063254d3e7",
    title: "Florence great Art & Crafts 4 days / 3 nights",
    price: "900",
    destinations: ["Firenze"],
    image: "https://d1s9epp90f3vor.cloudfront.net/tours/Florence-great-Art-Crafts.webp",
    duration: 4,
    nightDuration: 3,
    reviews: 0,
    rating: 0,
};

const romeSorrentoAmalfi: ToursEntity = {
    _id: "640f76207d1e3531866ad5bd",
    title: "Rome, Sorrento and the Amalfi Coast 6 days/5 nights Rome – Sorrento – Rome",
    price: "1000.0",
    destinations: [
        "Sorrento",
        "Capri",
        "Amalfi",
        "Positano",
        "Ravello",
        "Pompei",
        "Roma",
    ],
    image: "https://d1s9epp90f3vor.cloudfront.net/tours/Rome-Sorrento-Amalfi.webp",
    duration: 6,
    nightDuration: 5,
    reviews: 0,
    rating: 0,
};

const bestFlorence: ToursEntity = {
    _id: "640f15639604cb389ceda5ff",
    title: "Best of Florence",
    price: "1000.0",
    destinations: ["Firenze"],
    image: "https://d1s9epp90f3vor.cloudfront.net/tours/Best-of-Florence.webp",
    duration: 3,
    nightDuration: 2,
    reviews: 0,
    rating: 0,
};

const bestSorrentoAmalfi: ToursEntity = {
    _id: "64074b032f0fd856ca0526de",
    title: "The Best of Sorrento & The Amalfi Coast",
    price: "899.05",
    destinations: [
        "Naples",
        "Sorrento",
        "Capri",
        "Amalfi",
        "Positano",
        "Ravello",
        "Pompei",
    ],
    image: "https://d1s9epp90f3vor.cloudfront.net/tours/The-Best-of-Sorrento-The-Amalfi.webp",
    duration: 7,
    nightDuration: 6,
    reviews: 0,
    rating: 0,
};

export const toursHome: ToursByTourTypeEntity[] = [
    {
        tourType: "Into the region",
        title: "Into the region",
        body: "Our Region tours don’t have boundaries. We’ve designed travel experiences that will make you discover some of the most beautiful places in our Peninsula: a mix of art, culture, food and breathtaking landscapes.",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/amalfi2.wepb",
        tours: [bestFlorence, bestSorrentoAmalfi],
        type: "region",
    },
    {
        tourType: "Across Italy",
        title: "Across Italy",
        body: "Our tours don’t have boundaries. We’ve designed travel experiences that will make you discover some of the most beautiful places in our Peninsula: a mix of art, culture, food and breathtaking landscapes.",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/amalfi1.wepb",
        tours: [romeFlorenceTuscany, romeSorrentoAmalfi],
        type: "italy",
    },
    {
        tourType: "Down the city",
        title: "Down the city",
        body: "Our Cities tours don’t have boundaries. We’ve designed travel experiences that will make you discover some of the most beautiful places in our Peninsula: a mix of art, culture, food and breathtaking landscapes.",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/amalfi3.wepb",
        tours: [tasteTuscany, florenceArtCrafts],
        type: "city",
    },
];

export const toursByDestination: ToursByDestinationEntity[] = [
    {
        city: "Amalfi",
        title: "Amalfi",
        body: "City of Amalfi",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/amalfi.wepb",
        tours: [bestSorrentoAmalfi, romeSorrentoAmalfi],
    },
    {
        city: "Ravello",
        title: "Ravello",
        body: "City of Ravello",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/image1.wepb",
        tours: [bestSorrentoAmalfi, romeSorrentoAmalfi],
    },
    {
        city: "Naples",
        title: "body",
        body: "body",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/Naples.webp",
        tours: [bestSorrentoAmalfi],
    },
    {
        city: "Positano",
        title: "Positano",
        body: "City of Positano",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/image2.wepb",
        tours: [bestSorrentoAmalfi, romeSorrentoAmalfi],
    },
    {
        city: "Pompei",
        title: "Pompei",
        body: "City of Pompei",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/Pompeii.wepb",
        tours: [bestSorrentoAmalfi, romeSorrentoAmalfi],
    },
    {
        city: "Firenze",
        title: "Firenze",
        body: "City of Firenze",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/Florence.wepb",
        tours: [
            tasteTuscany,
            florenceArtCrafts,
            bestFlorence,
            romeFlorenceTuscany,
        ],
    },
    {
        city: "San Gimignano",
        title: "San Gimignano",
        body: "City of San Gimignano",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/image3.wepb",
        tours: [tasteTuscany, romeFlorenceTuscany],
    },
    {
        city: "Radda in Chianti",
        title: "Radda in Chianti",
        body: "City of Radda in Chianti",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/image4.wepb",
        tours: [tasteTuscany],
    },
    {
        city: "Roma",
        title: "Roma",
        body: "City of Roma",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/Rome.wepb",
        tours: [romeFlorenceTuscany, romeSorrentoAmalfi],
    },
    {
        city: "Pisa",
        title: "Pisa",
        body: "City of Pisa",
        // eslint-disable-next-line sonarjs/no-duplicate-string
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/image.wepb",
        tours: [romeFlorenceTuscany],
    },
    {
        city: "Sorrento",
        title: "Sorrento",
        body: "City of Sorrento",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/Sorrento.webp",
        tours: [bestSorrentoAmalfi, romeSorrentoAmalfi],
    },
    {
        city: "Val d’Orcia",
        title: "Val d’Orcia",
        body: "City of Val d’Orcia",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/image.wepb",
        tours: [tasteTuscany],
    },
    {
        city: "Capri",
        title: "Capri",
        body: "City of Capri",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/capri.wepb",
        tours: [bestSorrentoAmalfi, romeSorrentoAmalfi],
    },
];

export const navbarData: NavBarResponseTO = {
    toursHome,
    toursByDestination,
};

export const destinationToExplore: DestinationToExploreEntity[] = [
    {
        city: "Naples",
        searchableLabel: "title",
        region: "Campania",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/Naples.webp",
        title: "body",
        body: "body",
        latitude: "latitude",
        longitude: "longitude",
        aggregationId: "63f74622dc46b316ce82337d",
    },
    {
        city: "Milan",
        searchableLabel: "title",
        region: "Lombardia",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/image.wepb",
        title: "title",
        body: "body",
        latitude: "latitude",
        longitude: "longitude",
        aggregationId: "63d8e73f8710c3ba37cb3b0b",
    },
    {
        city: "Sorrento",
        searchableLabel: "Sorrento",
        region: "Campania",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/Sorrento.webp",
        title: "Sorrento",
        body: "City of Sorrento",
        latitude: "latitude",
        longitude: "longitude",
        aggregationId: "6401fa249c258caef14be2d6",
    },
    {
        city: "Capri",
        searchableLabel: "Capri",
        region: "Campania",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/capri.wepb",
        title: "Capri",
        body: "City of Capri",
        latitude: "latitude",
        longitude: "longitude",
        aggregationId: "640201d57dc96cd6cf0a98ae",
    },
    {
        city: "Amalfi",
        searchableLabel: "Amalfi",
        region: "Campania",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/amalfi.wepb",
        title: "Amalfi",
        body: "City of Amalfi",
        latitude: "latitude",
        longitude: "longitude",
        aggregationId: "640205c82521c19a0fbfff15",
    },
    {
        city: "Positano",
        searchableLabel: "Positano",
        region: "Campania",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/image.wepb",
        title: "Positano",
        body: "City of Positano",
        latitude: "latitude",
        longitude: "longitude",
        aggregationId: "640206cb2521c19a0fbfff22",
    },
    {
        city: "Ravello",
        searchableLabel: "Ravello",
        region: "Campania",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/image.wepb",
        title: "Ravello",
        body: "City of Ravello",
        latitude: "latitude",
        longitude: "longitude",
        aggregationId: "640207082521c19a0fbfff2f",
    },
    {
        city: "Pompei",
        searchableLabel: "Pompei",
        region: "Campania",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/Pompeii.wepb",
        title: "Pompei",
        body: "City of Pompei",
        latitude: "latitude",
        longitude: "longitude",
        aggregationId: "640215e9661992b79e9599c0",
    },
    {
        city: "Firenze",
        searchableLabel: "Firenze",
        region: "Toscana",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/Florence.wepb",
        title: "Firenze",
        body: "City of Firenze",
        latitude: "latitude",
        longitude: "longitude",
        aggregationId: "640f072ac11b8ed79063473b",
    },
    {
        city: "Roma",
        searchableLabel: "Roma",
        region: "Lazio",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/Rome.wepb",
        title: "Roma",
        body: "City of Roma",
        latitude: "latitude",
        longitude: "longitude",
        aggregationId: "640f1e5679aee6ac565c77ee",
    },
    {
        city: "Siena",
        searchableLabel: "Siena",
        region: "Toscana",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/image.wepb",
        title: "Siena",
        body: "City of Siena",
        latitude: "latitude",
        longitude: "longitude",
        aggregationId: "640f27521f86263fe44a6a42",
    },
    {
        city: "San Gimignano",
        searchableLabel: "San Gimignano",
        region: "Toscana",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/image.wepb",
        title: "San Gimignano",
        body: "City of San Gimignano",
        latitude: "latitude",
        longitude: "longitude",
        aggregationId: "640f27941f86263fe44a6a4f",
    },
    {
        city: "Pisa",
        searchableLabel: "Pisa",
        region: "Toscana",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/image.wepb",
        title: "Pisa",
        body: "City of Pisa",
        latitude: "latitude",
        longitude: "longitude",
        aggregationId: "640f280d1f86263fe44a6a5c",
    },
    {
        city: "Monteriggioni",
        searchableLabel: "Monteriggioni",
        region: "Toscana",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/image.wepb",
        title: "Monteriggioni",
        body: "City of Monteriggioni",
        latitude: "latitude",
        longitude: "longitude",
        aggregationId: "640f2a7309a247d05c9ef668",
    },
    {
        city: "Radda in Chianti",
        searchableLabel: "Radda in Chianti",
        region: "Toscana",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/image.wepb",
        title: "Radda in Chianti",
        body: "City of Radda in Chianti",
        latitude: "latitude",
        longitude: "longitude",
        aggregationId: "640f556e2131df0d899612d0",
    },
    {
        city: "Val d’Orcia",
        searchableLabel: "Val d’Orcia",
        region: "Toscana",
        image: "https://d1s9epp90f3vor.cloudfront.net/destinations/image.wepb",
        title: "Val d’Orcia",
        body: "City of Val d’Orcia",
        latitude: "latitude",
        longitude: "longitude",
        aggregationId: "640f57058ea3afa4c6f7cede",
    },
];

export const staticMedia: StaticMediaEntity = {
    headImage: "https://d1s9epp90f3vor.cloudfront.net/home/head-image.webp",
    previewImageVideo: "https://d1s9epp90f3vor.cloudfront.net/home/oceano.webp",
    stepperImage:
        "https://d1s9epp90f3vor.cloudfront.net/home/stepper-image.webp",
    video: "https://d1007vjwxrx9l.cloudfront.net/play-italy-videos/home/oceano.mp4",
};

const video: VideoreviewsEntity = {
    _id: "63dbcae25bbdd36ced56d430",
    name: "Name 1",
    lastname: "Surname 2",
    city: "Napoli",
    avatar: "https://d1s9epp90f3vor.cloudfront.net/video-reviews/avatar.wepb",
    previewImage:
        "https://d1s9epp90f3vor.cloudfront.net/video-reviews/previewImage.wepb",
    countryPrefix: "NA",
    video: "https://d1007vjwxrx9l.cloudfront.net/play-italy-videos/home/oceano.mp4",
};
export const videoReviews: VideoreviewsEntity[] = [
    video,
    video,
    video,
    video,
    video,
];

export const homeData: HomeResponseTO = {
    reviews: reviewContents,
    destinationToExplore,
    staticMedia,
};
export const brochures: BrochuresEntity[] = [
    {
        type: "Across Italy Brochure",
        description: "Across Italy Brochure description",
        brochure:
            "https://d1007vjwxrx9l.cloudfront.net/play-italy-brochures/c08b3c51-1d77-4c0e-ae70-f6f4b3d99feb.pdf",
    },
    {
        type: "Into the Region Brochure",
        description: "Into the Region Brochure description",
        brochure:
            "https://d1007vjwxrx9l.cloudfront.net/play-italy-brochures/65a928db-3c4e-4380-b028-83c0daa45cc5.pdf",
    },
    {
        type: "Down the city Brochure",
        description: "Down the city Brochure description",
        brochure:
            "https://d1007vjwxrx9l.cloudfront.net/play-italy-brochures/0caf5f99-5416-441b-acd3-704935903977.pdf",
    },
];
