import { IUserState } from "@/models/store/user";
import { ICheckoutState } from "@/models/store/checkout";
import {
    EDetailSection,
    EModalAuthType,
    ETypeToastMessage,
} from "../../models/common/enums";
import {
    IAuthState,
    ICommontState,
    IFavouriteState,
    IPackageState,
    TStore,
} from "../../models/store";
import { getDefaultCheckoutState } from "../mockData/checkout";

export const getDefaultCommonState = (): ICommontState => {
    return {
        loadingPage: false,
        toastMessageData: {
            message: "",
            type: ETypeToastMessage.INFO,
        },
    };
};

export const getDefaultPackageDetailState = (): IPackageState => {
    return {
        currentSection: EDetailSection.GENERAL,
    };
};

export const getDefaultUserState = (): IUserState => {
    return {
        userDetail: {
            _id: "",
            email: "",
            cognitoId: "",
            createdAt: "",
            updatedAt: "",
            birthDate: "",
            name: "",
            surname: "",
            favouriteTours: [],
            gender: "",
            orders: [],
            phone: "",
            status: "",
            username: "",
            marketingPromotions: false,
            policyPrivacy: false,
            terms: false,
            avatar: "",
        },
        newsletterEmail: "",
        newsletterModal: false,
    };
};

export const getDefaultAuthState = (): IAuthState => {
    return {
        modalAuthType: EModalAuthType.NONE,
    };
};

export const getDefaultFavouriteState = (): IFavouriteState => {
    return {
        isOpen: false,
    };
};

/* eslint-disable @typescript-eslint/no-empty-function */
export const emptyState: TStore = {
    checkoutState: { ...getDefaultCheckoutState() },
    dispatchCheckout: () => {},
    commonState: { ...getDefaultCommonState() },
    dispatchCommon: () => {},
    packageState: { ...getDefaultPackageDetailState() },
    userState: getDefaultUserState(),
    dispatchUser: () => {},
    dispatchPackage: () => {},
    action: { type: "EMPTY_ACTION", payload: null },
    getOrderData: () => ({} as never),
    sendStripeOrder: async () => {},
    authState: getDefaultAuthState(),
    favouriteState: getDefaultFavouriteState(),
    dispatchFavourite: () => {},
    dispatchAuth: () => {},
    openModalAuth: () => {},
    openModalFavourite: () => {},
    logout: () => {},
    setErrorMsg: () => {},
    addCreditCard: () => {},
    setUserDetail: () => {},
    changePassword: () => {},
    updateUserDetail: () => {},
    setSuccessMsg: () => {},
    deleteUser: () => {},
    handleStatusMsg: () => {},
    initStore: () => {},
    handleNewsletter: () => {},
    handleNewsletterCustom: () => {},
    uploadUserAvatar: () => {},
    afterSuccessSendOrder: () => {},
};
/* eslint-disable @typescript-eslint/no-empty-function */

export const containsNumber = (str: string): boolean => {
    return str.match(/\d/) !== null;
};

const updateInvoice = (
    state: ICheckoutState,
    addressStreet: string,
    addressProvince: string,
    addressCity: string
) => ({
    ...state,
    orderData: {
        ...state.orderData,
        invoice: {
            ...state.orderData.invoice!,
            addressStreet,
            addressProvince,
            addressCity,
        },
    },
});

const getAddressCity = (
    address: { offset: number; value: string }[]
): string => {
    return containsNumber(address?.at(0)?.value ?? "")
        ? address?.at(2)?.value ?? ""
        : address?.at(1)?.value ?? "";
};

export const setAddressAutofill = (
    address: { offset: number; value: string }[],
    state: ICheckoutState,
    addressValue: string
) => {
    const addressCity = getAddressCity(address);

    if (address && address.length > 4) {
        return updateInvoice(
            state,
            addressValue,
            address?.at(3)?.value ?? "",
            address?.at(2)?.value ?? ""
        );
    }
    if (address && address.length === 3) {
        return updateInvoice(
            state,
            addressValue,
            address?.at(1)?.value ?? "",
            address?.at(0)?.value ?? ""
        );
    }
    if (address && address.length < 3) {
        return updateInvoice(
            state,
            addressValue,
            address?.at(0)?.value ?? "",
            address?.at(0)?.value ?? ""
        );
    }
    return updateInvoice(
        state,
        addressValue,
        address?.at(2)?.value ?? "",
        addressCity
    );
};

const updateUserDetail = (
    state: IUserState,
    address: string,
    county: string,
    city: string
) => ({
    ...state,
    userDetail: {
        ...state.userDetail,
        address,
        county,
        city,
    },
});

export const setUserAddress = (
    address: { offset: number; value: string }[],
    state: IUserState,
    addressValue: string
) => {
    const addressCity = getAddressCity(address);

    if (address && address.length > 4) {
        return updateUserDetail(
            state,
            addressValue,
            address?.at(3)?.value ?? "",
            address?.at(2)?.value ?? ""
        );
    }
    if (address && address.length === 3) {
        return updateUserDetail(
            state,
            addressValue,
            address?.at(1)?.value ?? "",
            address?.at(0)?.value ?? ""
        );
    }
    if (address && address.length < 3) {
        return updateUserDetail(
            state,
            addressValue,
            address?.at(0)?.value ?? "",
            address?.at(0)?.value ?? ""
        );
    }
    return updateUserDetail(
        state,
        addressValue,
        address?.at(2)?.value ?? "",
        addressCity
    );
};
