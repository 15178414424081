import { MDBCheckbox, MDBRow } from "mdb-react-ui-kit";
import { useRouter } from "next/router";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useMemo, useState } from "react";
import styles from "@/styles/auth/Auth.module.scss";
import { isValidEmail } from "@/utils/common";
import { useStore } from "@/store/index";
import Btn from "@/common/Btn";
import { URLS } from "@/utils/constants";
import { verifyAccount } from "@/utils/queries";
import {
    EBtnType,
    ECognitoError,
    EModalAuthType,
    ERemeberMe,
    ESignCodeStatus,
} from "@/models/common/enums";
import { signinUser } from "@/utils/queries/user-queries";
import Input from "../../common/Input";

export type TLogin = {
    email: string;
    password: string;
};

const LoginWithEmail = () => {
    const dispatch = useStore(state => state.dispatchCommon);
    const dispatchUser = useStore(state => state.dispatchUser);
    const dispatchAuth = useStore(state => state.dispatchAuth);
    const setErrorMsg = useStore(state => state.setErrorMsg);
    const setSuccessMsg = useStore(state => state.setSuccessMsg);
    const [rememberMe, setRememberMe] = useState<boolean>(false);

    const [verifyCode, setVerifyCode] = useState<string>("");
    const [formState, setFormState] = useState<ESignCodeStatus>(
        ESignCodeStatus.NOVERIFY
    );
    const isVerify = formState === ESignCodeStatus.VERIFY;
    const isNoVerify = formState === ESignCodeStatus.NOVERIFY;

    const router = useRouter();

    const [user, setUser] = useState<TLogin>({
        email: "",
        password: "",
    });

    const isFieldValid = (
        value: string | undefined,
        checkValidity?: (v: string) => boolean
    ): boolean => {
        if (checkValidity) {
            return !!value && !!value.trim() && checkValidity(value);
        }

        return !!value && !!value.trim();
    };

    const isLoginButtonEnabled = useMemo(() => {
        return isValidEmail(user.email?.trim()) && user.password?.trim();
    }, [user]);

    const isVerifyButtonEnabled = useMemo(
        () => isFieldValid(verifyCode),
        [verifyCode]
    );

    const { mutate: signIn, isLoading } = useMutation(signinUser, {
        onSettled: () => dispatch({ type: "SET_LOADER_PAGE", payload: false }),
        onSuccess: data => {
            dispatchUser({ type: "SET_USER_DETAIL", payload: data });
            dispatchAuth({ type: "RESET_AUTH" });
            router.push(URLS.userProfile);
        },
        onError: (err: Error) => {
            if (err.name === ECognitoError.USERNOTCONFIRMED) {
                setFormState(ESignCodeStatus.VERIFY);
                return;
            }
            setErrorMsg(err as never);
        },
    });

    const { mutate: verify, isLoading: isLoadingVerify } = useMutation(
        verifyAccount,
        {
            onSettled: () =>
                dispatch({ type: "SET_LOADER_PAGE", payload: false }),
            onSuccess: () => {
                setSuccessMsg("Account verified, please log in again.");
                setFormState(ESignCodeStatus.NOVERIFY);
            },
            onError: err => setErrorMsg(err as never),
        }
    );

    useEffect(() => {
        dispatch({
            type: "SET_LOADER_PAGE",
            payload: isLoading || isLoadingVerify,
        });
    }, [isLoading, isLoadingVerify]);

    const onChange = (e: {
        stopPropagation: () => void;
        preventDefault: () => void;
        target: {
            name: keyof Partial<TLogin>;
            value: unknown;
        };
    }) => {
        e.stopPropagation();
        e.preventDefault();

        const { name, value } = e.target;

        setUser({ ...user, [name]: value });
    };

    const onChangeVerification = (e: {
        stopPropagation: () => void;
        preventDefault: () => void;
        target: {
            name: keyof Partial<{ verifyCode: string }>;
            value: unknown;
        };
    }) => {
        e.stopPropagation();
        e.preventDefault();

        const { value } = e.target;

        setVerifyCode((value as string).trim());
    };

    const onClick = () => {
        if (isNoVerify) {
            signIn({
                username: user.email,
                password: user.password,
            });
        } else {
            verify({ username: user.email, code: verifyCode });
        }
    };

    useEffect(() => {
        const checkTabPress = (e: KeyboardEvent, id: string) => {
            if (e.key === "Tab") {
                switch (id) {
                    case "email":
                        document?.getElementById("password")?.focus();
                        break;
                    case "password":
                        document?.getElementById("email")?.focus();
                        break;
                    default:
                        break;
                }
            }
        };
        document
            ?.getElementById("email")
            ?.addEventListener("keyup", event => checkTabPress(event, "email"));
        document
            ?.getElementById("password")
            ?.addEventListener("keyup", event =>
                checkTabPress(event, "password")
            );
    }, []);

    useEffect(() => {
        localStorage.setItem(
            "rememberMe",
            rememberMe ? ERemeberMe.REMEMBER : ERemeberMe.NOTREMEMBER
        );
        dispatchAuth({ type: "SET_REMEMBER_ME", payload: rememberMe });
    }, [dispatchAuth, rememberMe]);

    return (
        <>
            {isNoVerify && (
                <form>
                    <MDBRow>
                        <Input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email"
                            inputProps={{ autoComplete: "username" }}
                            columnProps={{ size: 12, className: "my-3" }}
                            required
                            value={user.email}
                            onDelete={() => setUser({ ...user, email: "" })}
                            onChange={onChange as never}
                            validationCb={value =>
                                isValidEmail(value as string)
                            }
                        />
                        <Input
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Password"
                            inputProps={{ autoComplete: "current-password" }}
                            columnProps={{ size: 12, className: "my-3" }}
                            required
                            value={user.password}
                            hideValueAttribute
                            onChange={onChange as never}
                        />
                    </MDBRow>
                    <MDBRow className="g-0 py-4">
                        <div
                            className={`${styles.checkboxContainer} d-flex align-items-center`}
                        >
                            <MDBCheckbox
                                label="Remember me"
                                className={styles.customCheckboxChecked}
                                checked={rememberMe}
                                onChange={() => {
                                    setRememberMe(!rememberMe);
                                }}
                            />
                            <span
                                className={`${styles.infoTextLinksSM} ms-2`}
                                onClick={() =>
                                    dispatchAuth({
                                        type: "SET_AUTH_TYPE",
                                        payload: EModalAuthType.FORGOT_PASSWORD,
                                    })
                                }
                            >
                                Did you forget your password ?
                            </span>
                        </div>
                    </MDBRow>
                </form>
            )}
            {isVerify && (
                <MDBRow>
                    <Input
                        type="text"
                        name="verifyCode"
                        id="verifyCode"
                        placeholder="Verification Code"
                        diasbleStatusIcon
                        columnProps={{ size: 12, className: "my-3" }}
                        required
                        value={verifyCode}
                        onChange={onChangeVerification as never}
                    />
                </MDBRow>
            )}

            <MDBRow className="g-0 py-2">
                <Btn
                    btnType={EBtnType.PRIMARY}
                    onClick={onClick}
                    disabled={
                        (isNoVerify && !isLoginButtonEnabled) ||
                        (isVerify && !isVerifyButtonEnabled) ||
                        isLoading ||
                        isLoadingVerify
                    }
                    fullWith
                >
                    {isNoVerify && "Login"}
                    {isVerify && "Verify"}
                </Btn>
            </MDBRow>
            {isNoVerify && (
                <MDBRow className="g-0 mt-3">
                    <p className={`${styles.infoText} text-center`}>
                        <span
                            className={`text-center ${styles.infoTextDarkLinks}`}
                            onClick={() =>
                                dispatchAuth({
                                    type: "SET_AUTH_TYPE",
                                    payload: EModalAuthType.PRELOGIN,
                                })
                            }
                        >
                            Retun to login?
                        </span>
                    </p>
                </MDBRow>
            )}
        </>
    );
};

export default LoginWithEmail;
