import { ICheckoutState, TActionCheckout } from "@/models/store/checkout";
import { IUser } from "@/models/user";
import { generateTravelers } from "@/utils/common/checkout";
import { setAddressAutofill } from "@/utils/common/store";
import { getDefaultCheckoutState } from "@/utils/mockData/checkout";

export const reducerCheckout = (
    state: ICheckoutState,
    action: TActionCheckout,
    userDetail?: IUser
): ICheckoutState => {
    switch (action.type) {
        case "UPDATE_CHECKOUT_STATE":
            return {
                ...state,
                ...action.payload,
            };
        case "UPDATE_ORDER_DATA":
            return {
                ...state,
                orderData: {
                    ...state.orderData,
                    ...action.payload,
                },
            };
        case "SET_ROOMS":
            return {
                ...state,
                orderData: {
                    ...state.orderData,
                    rooms: action.payload,
                },
            };
        case "SET_ROOMS_AND_GENERATE_TRAVELERS": {
            const rooms = action.payload;
            const existingTravelers = state.orderData.travelers || [];

            const newTravelers = generateTravelers(
                rooms,
                userDetail,
                existingTravelers
            );

            return {
                ...state,
                orderData: {
                    ...state.orderData,
                    travelers: newTravelers,
                    rooms,
                },
            };
        }

        case "SET_TOTAL_AMOUNT":
            return {
                ...state,
                orderData: {
                    ...state.orderData,
                    totalAmount: action.payload,
                },
            };
        case "SET_COUPON":
            return {
                ...state,
                orderData: {
                    ...state.orderData,
                    coupon: action.payload,
                },
            };
        case "SET_PRICES": {
            const { netPrice, grossPrice, markup } = action.payload;
            return {
                ...state,
                orderData: {
                    ...state.orderData,
                    totalAmount: grossPrice,
                    originalAmount: grossPrice,
                    netPrice,
                    markup,
                },
            };
        }
        case "SET_SELECTED_SLOT": {
            return {
                ...state,
                orderData: {
                    ...state.orderData,
                    selectedSlot: action.payload,
                },
            };
        }
        case "SET_TRAVELERS":
            return {
                ...state,
                orderData: {
                    ...state.orderData,
                    travelers: action.payload,
                },
            };
        case "SET_TRAVELER": {
            const travelers = state.orderData.travelers.map(curr =>
                curr.travelerNumber === action.payload.travelerNumber
                    ? action.payload
                    : curr
            );
            return {
                ...state,
                orderData: {
                    ...state.orderData,
                    travelers,
                },
            };
        }
        case "SET_MAIN_CUSTOMER":
            return {
                ...state,
                orderData: {
                    ...state.orderData,
                    mainCustomer: action.payload,
                },
            };
        case "SET_STATUS_STEPPER":
            return { ...state, statusStep: action.payload };
        case "SET_ACTIVE_NEXT":
            return { ...state, activeNext: action.payload };
        case "SET_PAYMENT_METHOD":
            return { ...state, paymentMethod: action.payload };
        case "SET_BTN_NEXT_TEXT":
            return { ...state, btnNextText: action.payload };
        case "SET_BTN_NEXT": {
            const { activeNext, btnNextText } = action.payload;
            return { ...state, btnNextText, activeNext };
        }
        case "RESET_TIMER":
            return { ...state, startTime: new Date() };

        case "SET_TOUR_DETAIL":
            return {
                ...state,
                tourDetail: { ...state.tourDetail, ...action.payload },
            };
        case "SET_SUBMIT_PAYMENT": {
            return { ...state, submitPayment: action.payload };
        }
        case "SET_LANGUAGE": {
            return { ...state, languageSelected: action.payload };
        }
        case "RESET_CHECKOUT": {
            return { ...getDefaultCheckoutState() };
        }
        case "SET_ADDRESS_AUTOFILL": {
            return setAddressAutofill(
                action.payload.address,
                state,
                action.payload.addressValue
            );
        }
        case "SET_TERMS": {
            return {
                ...state,
                orderData: {
                    ...state.orderData,
                    termsConditionPrivacyPolicy: action.payload,
                },
            };
        }
        case "SET_INSURANCE": {
            return {
                ...state,
                orderData: {
                    ...state.orderData,
                    InsurancePolicy: action.payload,
                },
            };
        }
        case "SET_CLICKDATE": {
            return {
                ...state,
                clickDate: action.payload,
            };
        }
        case "SET_CLICKLANGUAGE": {
            return {
                ...state,
                clickLanguage: action.payload,
            };
        }
        case "SET_CLICKCONFIRMDATE": {
            return {
                ...state,
                clickConfirmDate: action.payload,
            };
        }
        default:
            return state;
    }
};
